import { queryOptions } from "@tanstack/react-query";

import requestOptions from "@/lib/request-options";

import routes from "./routes";

import type { TContact, TContactSearchResult } from "./types";

/* Helpers
/* ------------------------------------------------------------------ */

function propertiesOrDefaults(data: TContact) {
  return {
    age: "",
    email: "",
    firstName: "",
    lastName: "",
    ...data,
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findByQuery = {
  queryOptions(query: string) {
    return queryOptions({
      queryKey: ["contacts", query] as const,
      queryFn: async function () {
        const url = routes.search(query);
        return await window.auth.client.get(url).json<TContactSearchResult[]>();
      },
      enabled: !!query,
    });
  },
};

const findById = {
  queryOptions(id: string) {
    return queryOptions({
      queryKey: ["contacts", id] as const,
      queryFn: async function () {
        const url = routes.show(id);
        const resp = await window.auth.client.get(url).json<TContact>();
        return resp;
      },
    });
  },
  queryFn: async function (id: string) {
    const url = routes.show(id);
    const resp = await window.auth.client.get(url).json<TContact>();
    return resp;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

const save = async function (contact: TContact) {
  const { method, url } = requestOptions("contacts", contact._id);
  const resp = await window.auth.client[method](url, {
    json: contact,
    ...requestOptions("contacts", contact._id),
  }).json<TContact>();

  return resp;
};

const deleteById = async function (id: string) {
  const url = routes.delete(id);
  const resp = await window.auth.client.delete(url).json<TContact>();
  return resp;
};

const forgetById = async function (id: string) {
  const url = routes.forget(id);
  const resp = await window.auth.client.post(url).json<TContact>();
  return resp;
};

export default {
  deleteById,
  forgetById,
  findByQuery,
  findById,
  propertiesOrDefaults,
  save,
};
