import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Typography from "@mui/material/Typography";

import type { FieldError } from "react-hook-form";

export default function FormErrorMessage({
  error,
  gutterBottom = false,
  text,
  sx = {},
}: {
  error?: FieldError | Error;
  gutterBottom?: boolean;
  text?: string;
  sx?: Record<string, unknown>;
}) {
  const message = text || (error && error.message);
  if (message) {
    return (
      <Typography
        className="animation-fade-in"
        component="div"
        data-testid="form-error-message"
        sx={[
          {
            display: "flex",
            alignItems: "center",
            color: "#d00",
            ...sx,
          },
          gutterBottom
            ? {
                marginBottom: "1rem",
              }
            : {
                marginBottom: "0",
              },
        ]}
        variant="body2"
      >
        <WarningRoundedIcon style={{ marginRight: "0.35rem" }} />
        {message}
      </Typography>
    );
  }

  return null;
}
