import { Bank } from "phosphor-react";
import { useDocumentTitle } from "usehooks-ts";

import { useDrawer } from "@/components/Drawer";
import PartnerNewDrawer from "@/components/Drawers/PartnerNewDrawer";
import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { MainDetail } from "@/components/MainDetail";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import { usePartners } from "@/models/partner/hooks";

import MainList from "./components/MainList";
import PartnerShow from "./show";

const Empty = function () {
  return (
    <EmptyIndexMessage
      icon={<Bank size={100} weight="thin" />}
      text="Select a partner"
    />
  );
};

const PartnersRoute = function () {
  useDocumentTitle("Back Office — Partners");

  const partnerNewDrawer = useDrawer(PartnerNewDrawer);
  const showAlert = useAlert();

  const partners = usePartners();

  const successCallback = function () {
    partners.refetch();
    showAlert({
      message: "Partner created!",
    });
    partnerNewDrawer.remove();
  };

  const Main = function () {
    return (
      <>
        <TitleBar loading={partners.isLoading} title="Partners">
          <TitleBarButton
            icon="add"
            onClick={() => {
              partnerNewDrawer.show({
                cancel() {
                  partnerNewDrawer.remove();
                },
                data: {},
                successCallback,
              });
            }}
          >
            New Partner
          </TitleBarButton>
        </TitleBar>
        {Boolean(partners.data?.length) && (
          <MainList partners={partners.data} />
        )}
      </>
    );
  };

  return (
    <>
      <MainDetail
        MainComponent={Main}
        DetailComponent={PartnerShow}
        detailPath={clientRoutes.partners.showPath}
        EmptyComponent={Empty}
      />
    </>
  );
};

export default PartnersRoute;
