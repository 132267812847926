import { useState } from "react";
import { useNavigate } from "react-router";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

import FormErrorMessage from "@/components/FormErrorMessage";
import UPDialog from "@/components/UPDialog";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import Contact from "@/models/contact";

export default function DeleteDialog({ close, id }) {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <UPDialog
      close={close}
      Icon={DeleteOutlinedIcon}
      Title="Are you sure you want to permanently delete this contact?"
      Content="You cannot undo this action."
      Actions={
        <>
          <LoadingButton
            color="neutral"
            loading={isLoading}
            onClick={close}
            variant="outlined"
            sx={{ minWidth: 140 }}
          >
            Never mind
          </LoadingButton>
          <LoadingButton
            color="error"
            loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await Contact.deleteByID(id);
                setIsLoading(false);
                showAlert({ message: "Contact deleted" });
                navigate(clientRoutes.contacts.index());
                close();
              } catch (err) {
                setIsLoading(false);
                setErrorMessage(err.response?.data?.message ?? err.message);
              }
            }}
            variant="contained"
          >
            Yes, Delete Contact
          </LoadingButton>
          {errorMessage ? (
            <FormErrorMessage
              text={errorMessage}
              sx={{ flexBasis: "100%", mt: 1, justifyContent: "center" }}
            />
          ) : null}
        </>
      }
    />
  );
}
