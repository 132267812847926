import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ApplicationCancelDialog } from "@/components/ApplicationCancelDialog";
import { useDrawer } from "@/components/Drawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import { PS_COUPON_DEFAULTS } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import Application from "@/models/application";
import { useApplication } from "@/models/application/hooks";
import { useProfile } from "@/models/profile/hooks";
import { useScholarship } from "@/models/scholarship/hooks";

import ShowView from "./components/ShowView";

import type { TCoupon } from "@/models/coupon/types";

export default function ShowApplication() {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const applicationQuery = useApplication(params.email, params.partner, {
    enabled: Boolean(params.email) && Boolean(params.partner),
    placeholderData: location.state?.application,
  });

  const scholarshipQuery = useScholarship(params.email, params.partner, {
    enabled: Boolean(params.email) && Boolean(params.partner),
    placeholderData: location.state?.scholarship,
  });

  const profileQuery = useProfile(
    applicationQuery.data?.student.email,
    applicationQuery.data?.partner._id,
    {
      enabled: Boolean(applicationQuery.data),
    },
  );

  const updateMutation = useMutation({
    mutationFn: Application.save,
    onSuccess() {
      showAlert({
        message: "Application updated",
      });
      applicationQuery.refetch();
      queryClient.invalidateQueries({
        queryKey: Application.findAllByStatus.queryKey(),
      });
    },
    onError() {
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const cancelMutation = useMutation({
    mutationFn() {
      return Application.cancel(applicationQuery.data);
    },
    onSuccess() {
      showAlert({
        message: `Application cancelled`,
      });
      queryClient.invalidateQueries({
        queryKey: Application.findAllByStatus.queryKey(),
      });
      setShowCancelDialog(false);
      navigate(clientRoutes.applications.index("pendingReview"));
    },
  });

  const showCoupon = function () {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: {
        ...PS_COUPON_DEFAULTS,
        partner: {
          _id: applicationQuery.data.partner._id,
          name: applicationQuery.data.partner.name,
        },
        scopedUsers: [profileQuery.data.email],
      },
      errorCallback(error) {
        if (error instanceof Error && error.message.includes("409")) {
          showAlert({
            message: `Error! This coupon code already exists!`,
            horizontal: "center",
            severity: "error",
            duration: 10000,
          });
        } else {
          showAlert({
            message: `An error has ocurred!`,
            horizontal: "center",
            severity: "error",
            duration: 10000,
          });
        }
      },
      successCallback(data: TCoupon) {
        showAlert({
          message: `Successfully created coupon with code: ${data.code}`,
          horizontal: "center",
          duration: null,
        });
        couponFormDrawer.remove();
      },
    });
  };

  return (
    <Layout>
      <>
        <TitleBar
          backRoute={matches ? null : ".."}
          loading={
            scholarshipQuery.isLoading ||
            profileQuery.isLoading ||
            applicationQuery.isLoading
          }
          title={params.email ?? "Loading"}
        >
          <TitleBarButton
            onClick={() => {
              setShowCancelDialog(true);
            }}
            startIcon={<NotInterestedIcon />}
          >
            Cancel Application
          </TitleBarButton>
          <TitleBarButton onClick={showCoupon} icon="add">
            New Coupon
          </TitleBarButton>
        </TitleBar>

        <Layout.Content>
          <ApplicationCancelDialog
            cancelMutation={cancelMutation}
            close={() => {
              setShowCancelDialog(false);
            }}
            isOpen={showCancelDialog}
          />
          {applicationQuery.isLoading ? (
            <div>Loading...</div>
          ) : (
            <ShowView
              applicationQuery={applicationQuery}
              isSaving={updateMutation.isPending}
              profileQuery={profileQuery}
              scholarshipQuery={scholarshipQuery}
              saveApplication={updateMutation.mutate}
            />
          )}
        </Layout.Content>
      </>
    </Layout>
  );
}
