import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { createCoupon, saveCoupon } from "@/models/coupon";
import CouponForm from "@/routes/Coupons/components/CouponForm";

import type { TCoupon } from "@/models/coupon/types";

export default DrawerManager.create(function ({
  cancel,
  data,
  errorCallback,
  successCallback,
}: {
  cancel: () => void;
  data: TCoupon;
  errorCallback: (error: Error) => void;
  successCallback: (data: TCoupon) => void;
}) {
  const EXISTING_COUPON = Boolean(data?.createdAt);

  const mutation = useMutation({
    mutationFn: EXISTING_COUPON ? saveCoupon : createCoupon,
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  });

  return (
    <Drawer
      loading={mutation.isPending}
      title={`${EXISTING_COUPON ? "Edit" : "New"} Coupon`}
    >
      <CouponForm
        cancel={cancel}
        onSubmit={(newValues) => {
          mutation.mutate(newValues);
        }}
        couponData={data}
        doesCodeExist={EXISTING_COUPON}
        isSaving={mutation.isPending}
      />
    </Drawer>
  );
});
