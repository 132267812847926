import { Link } from "react-router";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";

import { fixedForwardRef } from "@/lib/fixed-forward-ref";

import { sx } from "./style";

import type { ReactNode } from "react";

export type IconOption = "add" | "edit" | "view" | "delete" | "disableView";

const LinkBehavior = fixedForwardRef<HTMLAnchorElement>(
  function LinkBehavior(props, ref) {
    return <Link ref={ref} to="/" {...props} role={undefined} />;
  },
);

const getIcon = function (icon: IconOption) {
  switch (icon) {
    case "add":
      return <AddCircleOutlineIcon />;
    case "edit":
      return <EditIcon />;
    case "view":
      return <VisibilityIcon />;
    case "delete":
      return <DeleteIcon />;
    case "disableView":
      return <VisibilityOffIcon color="disabled" />;
    default:
      new Error(`Icon "${icon}" not supported`);
  }
};

type VariantProps =
  | {
      icon: IconOption;
      startIcon?: undefined;
    }
  | {
      icon?: undefined;
      startIcon: ReactNode;
    };

type Props = {
  [x: string]: unknown;
  children: ReactNode;
  to?: string;
} & VariantProps;

export const TitleBarButton = function (props: Props) {
  const { ...rest } = props;

  return (
    <Button
      color="primary"
      component={props.to ? LinkBehavior : null}
      variant="contained"
      startIcon={props.icon ? getIcon(props.icon) : null}
      sx={sx}
      {...rest}
    >
      {props.children}
    </Button>
  );
};
