const clientRoutes = {
  contacts: {
    root: "contacts",
    indexPath: "/contacts/*",
    index() {
      return "/contacts";
    },
    resultsPath: "search/:query",
    results(query) {
      return `search/${query}`;
    },
    showPath: ":_id",
    show(_id) {
      return `/contacts/${_id}`;
    },
  },

  settings: {
    root: "settings",
    indexPath: "/settings",
    index() {
      return "/settings";
    },
  },

  status: {
    root: "status",
    indexPath: "/status",
    index() {
      return "/status";
    },
  },

  login: {
    root: "login",
    indexPath: "/login",
    index() {
      return "/login";
    },
  },

  insights: {
    root: "insights",
    indexPath: "/insights",
    index() {
      return "/insights";
    },
  },

  inventorySearch: {
    root: "inventory-search",
    indexPath: "/inventory-search/*",
    index() {
      return "/inventory-search";
    },
    showPath: ":_id",
    show(_id) {
      return `/inventory-search/${_id}`;
    },
  },

  catalogue: {
    root: "catalogue",
    indexPath: "/catalogue/*",
    index() {
      return "/catalogue";
    },
    newPath: "new",
    new() {
      return "new";
    },
    itemsPath: ":partner_id/items",
    items(partner_id) {
      return `${partner_id}/items`;
    },
    showPath: ":_id",
    show(_id) {
      return `/catalogue/${_id}`;
    },
  },

  applications: {
    root: "applications",
    indexPath: "/applications/:status/*",
    index(status) {
      return `/applications/${status}`;
    },
    showPath: ":email/:partner",
    show(email, partner) {
      return `${email}/${partner}`;
    },
  },

  coupons: {
    root: "coupons",
    indexPath: "/coupons/*",
    index() {
      return "/coupons";
    },
    showPath: ":_id",
    show(_id) {
      return _id;
    },
  },

  scholarships: {
    root: "scholarships",
    indexPath: "/scholarships/:status/*",
    index(status) {
      return `/scholarships/${status}`;
    },
    showPath: ":author/:partner",
    show(author, partner) {
      return `${author}/${partner}`;
    },
  },

  orders: {
    root: "orders",
    indexPath: "/orders/*",
    index() {
      return "/orders";
    },
    showPath: ":_id",
    show(_id) {
      return `/orders/${_id}`;
    },
    refund(orderId) {
      return `orders/${orderId}}`;
    },
  },

  fulfillment: {
    root: "fulfillment",
    show(orderId) {
      return `fulfillment/${orderId}`;
    },
  },

  marketingLists: {
    root: "marketing/lists",
    indexPath: "/marketing/lists/*",
    index() {
      return "/marketing/lists";
    },
    pendingPath: "/marketing/pending/*",
    pending() {
      return "/marketing/pending";
    },
  },

  profiles: {
    root: "profiles",
    indexPath: "/profiles/*",
    index() {
      return "/profiles";
    },
    showAllPath: "all/:email",
    showAll(email) {
      return `all/${email}`;
    },
    showPath: ":partnerId/:email",
    show(partnerId, email) {
      return `/profiles/${partnerId}/${email}`;
    },
  },

  partners: {
    root: "partners",
    indexPath: "/partners/*",
    index() {
      return "/partners";
    },
    showPath: ":_id",
    show(_id) {
      return `/partners/${_id}`;
    },
  },

  mentorSearch: {
    root: "mentors/search",
    email: {
      root: "mentors/search/email",
      indexPath: "/mentors/search/email/*",
      index() {
        return "/mentors/search/email";
      },
      showPath: ":email",
      show(email) {
        return `/mentors/search/email/${email}`;
      },
    },
    catalogue: {
      root: "mentors/search/catalogue",
      indexPath: "/mentors/search/catalogue/*",
      index() {
        return "/mentors/search/catalogue";
      },
      showPath: ":id",
      show(id) {
        return id;
      },
    },
  },

  mentorAssignments: {
    root: "mentor/assignments",
    indexPath: "/mentor/assignments/*",
    index() {
      return "/mentor/assignments";
    },
  },
};

export default clientRoutes;
