const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  search(query) {
    return `${BASE}/v1/text/contacts/?q=${query}` as const;
  },
  forget(id) {
    return `${BASE}/crm/forget/${id}` as const;
  },
  show(id) {
    return `${BASE}/v2/q/contacts/${id}` as const;
  },
  post() {
    return `${BASE}/crm/contacts` as const;
  },
  patch(id) {
    return `${BASE}/crm/contacts/${id}` as const;
  },
  delete(id) {
    return `${BASE}/crm/contacts/${id}` as const;
  },
} as const;

export default routes;
