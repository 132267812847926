import { Link as RouterLink } from "react-router";

import IconButton from "@mui/material/IconButton";

const Arrow = function () {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
      data-testid="ArrowBackIosIcon"
      viewBox="0 0 24 24"
    >
      <path
        style={{ transform: "translateX(4px)" }}
        d="M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"
      ></path>
    </svg>
  );
};

export const BackButton = function BackButton({
  onClick,
  to,
}: {
  onClick?: () => void;
  to?: string;
}) {
  if (to) {
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        component={RouterLink}
        to={to}
      >
        <Arrow />
      </IconButton>
    );
  } else if (onClick) {
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        onClick={onClick}
      >
        <Arrow />
      </IconButton>
    );
  }
};
