import routes from "./routes";

import type { TCart } from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const find = {
  queryFn: async function (partnerId: string, email: string) {
    const url = routes.show(partnerId, email);
    const resp = await window.auth.client.get(url).json<TCart>();
    return resp;
  },
  queryKey(partnerId: string, email: string) {
    return ["cart", partnerId, email] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

const addLineItem = async function (
  partnerId: string,
  owner: string,
  inventoryItemId: string,
) {
  const url = routes.addLineItem(partnerId);
  return await window.auth.client.post(url, {
    json: {
      _id: inventoryItemId,
      owner,
      partner: {
        _id: partnerId,
      },
    },
  });
};

const removeLineItem = async function (
  partnerId: string,
  owner: string,
  itemId: string,
) {
  const url = routes.removeLineItem(partnerId, itemId);
  return await window.auth.client.delete(url, {
    json: {
      owner,
    },
  });
};

const applyCoupon = async function (
  partnerId: string,
  owner: string,
  code: string,
) {
  const url = routes.applyCoupon(partnerId);
  return await window.auth.client.post(url, {
    json: {
      code,
      owner,
    },
  });
};

const removeCoupon = async function (
  partnerId: string,
  owner: string,
  code: string,
) {
  const url = routes.removeCoupon(partnerId, code);
  return await window.auth.client.delete(url, {
    json: {
      owner,
    },
  });
};

const reset = async function (partnerId: string, email: string) {
  const url = routes.reset(partnerId, email);
  return await window.auth.client.post(url);
};

export default {
  find,
  reset,
  addLineItem,
  removeLineItem,
  applyCoupon,
  removeCoupon,
};
