import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { CellLink } from "@/components/CellLink";
import clientRoutes from "@/lib/client-routes";
import Contact from "@/models/contact";

import type { TContact } from "@/models/contact/types";
import type { TProfile } from "@/models/profile/types";

const extractInfo = function (contact: TContact) {
  return (
    <>
      <TableCell>
        <CellLink url={clientRoutes.contacts.show(contact._id)} />
        {extractEmail(contact.profiles)}
      </TableCell>
      <TableCell>
        <CellLink url={clientRoutes.contacts.show(contact._id)} />
        {extractName(contact.profiles)}
      </TableCell>
      <TableCell>
        <CellLink url={clientRoutes.contacts.show(contact._id)} />
        {extractPartners(contact.profiles)}
      </TableCell>
    </>
  );
};

const extractPartners = function (profiles: TProfile[]) {
  return (
    <>
      {profiles.map((p, i) => {
        return <div key={`${p.uuid}-${i}`}>{p.partner.name}</div>;
      })}
    </>
  );
};

const extractEmail = function (profiles: Array<TProfile>) {
  return (
    <>
      {profiles.map((p, i) => {
        return <div key={`${p.uuid}-${i}`}>{p.email}</div>;
      })}
    </>
  );
};

const extractName = function (profiles: Array<TProfile>) {
  return (
    <>
      {profiles.map((p, i) => {
        return (
          <div key={`${p.uuid}-${i}`}>{`${p.firstName} ${p.lastName}`}</div>
        );
      })}
    </>
  );
};

const Status = function ({ text }) {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderBottomRightRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        padding: 2,
        textAlign: "center",
      }}
    >
      {text}
    </Box>
  );
};

export default function SearchResults() {
  const params = useParams();
  const searchQuery = useQuery(Contact.findByQuery.queryOptions(params.query));
  const contactsQuery = useQuery({
    queryKey: ["contacts", "findByIds"],
    queryFn() {
      return Promise.all(
        searchQuery.data.map((c) => Contact.findById.queryFn(c.contactId)),
      );
    },
    enabled: !!searchQuery.data?.length,
  });

  const LOADING = searchQuery.isLoading || contactsQuery.isLoading;

  const contactsList = contactsQuery.data ?? [];

  return (
    <>
      {LOADING ? <LinearProgress color="secondary" /> : null}

      {searchQuery.isError ? (
        <Status text={`⚠️ ${searchQuery.error.message}`} />
      ) : null}

      {!LOADING && contactsList?.length < 1 ? (
        <Alert sx={{ mt: 1 }} severity="error">
          No results.
        </Alert>
      ) : null}

      {!LOADING && contactsList?.length ? (
        <div>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: (theme) => {
                    return theme.palette.mode === "dark" ? "#222" : "#f3f3f3";
                  },
                }}
              >
                <TableCell>Email(s)</TableCell>
                <TableCell>Name(s)</TableCell>
                <TableCell>Partner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactsList.map((c, i) => {
                return (
                  <TableRow key={`${c._id}-${i}`}>{extractInfo(c)}</TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  );
}
