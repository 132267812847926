import { useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useState } from "react";
import { useLocation, useParams } from "react-router";

import { Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";

import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import MentorDrawer from "@/components/Drawers/MentorDrawer";
import MentorQualifiedCoursesDrawer from "@/components/Drawers/MentorQualifiedCoursesDrawer";
import { EditTextNotepad } from "@/components/EditTextNotepad";
import { Spinner } from "@/components/Spinner";
import { UPChip } from "@/components/UPChip";
import { useAlert } from "@/hooks/use-alert";
import Mentor from "@/models/mentor";
import { useMentorByEmail } from "@/models/mentor/hooks";

import DeleteDialog from "../components/DeleteDialog";
import InventoryTable from "../components/InventoryTable";

import type { TMentor } from "@/models/mentor/types";

function sortByPartnerName(a, b) {
  if (a.partner.name < b.partner.name) {
    return -1;
  } else if (a.partner.name > b.partner.name) {
    return 1;
  }
  return 0;
}

export default function MentorSearchShow() {
  const { email } = useParams();
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const location = useLocation();

  const mentor = useMentorByEmail(email, {
    placeholderData: location?.state?.mentor,
  });

  const mentorDrawer = useDrawer(MentorDrawer);
  const qualifiedCoursesDrawer = useDrawer(MentorQualifiedCoursesDrawer);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const editMentor = (mentorData: TMentor) => {
    mentorDrawer.show({
      cancel: mentorDrawer.remove,
      mentor: mentorData,
      successCallback() {
        mentor.refetch();
        queryClient.invalidateQueries({
          queryKey: Mentor.findAll.queryKey(),
        });
        mentorDrawer.remove();
        showAlert({
          message: "Mentor saved",
        });
      },
      title: "Edit Mentor",
    });
  };

  const editQualifiedCourses = () => {
    qualifiedCoursesDrawer.show({
      cancel: qualifiedCoursesDrawer.remove,
      email,
      items: mentor.data?.qualifiedCatalogueItems || [],
      onSuccess: mentor.refetch,
    });
  };

  return (
    <>
      {mentor.isError ? (
        <Alert sx={{ mt: 1 }} severity="error">
          {mentor.error instanceof HTTPError &&
          mentor.error.response.status === 404
            ? "Could not find mentor"
            : "An error has occurred"}
        </Alert>
      ) : null}
      {mentor.isLoading ? <LinearProgress color="secondary" /> : null}
      {mentor.data ? (
        <>
          <Typography
            variant="h5"
            sx={{
              mb: 1,
              mt: 1,
              fontWeight: 700,
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {mentor.data.firstName} {mentor.data.lastName}
            <Spinner
              loading={mentor.isRefetching || mentor.isLoading}
              sx={{ ml: 1 }}
            />
          </Typography>

          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <ButtonGroup color="primary" variant="outlined">
              <Button
                onClick={() => {
                  editMentor(mentor.data);
                }}
              >
                Edit Mentor
              </Button>
              <Button onClick={editQualifiedCourses}>
                Edit Qualified Catalogue Items
              </Button>
              {mentor.data.assignedInventory?.length ? (
                <Tooltip title="Only mentors with no qualifications or assignments can be deleted">
                  <div>
                    <Button color="error" variant="contained" disabled>
                      Delete Mentor
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button color="error" onClick={() => setConfirmDelete(true)}>
                  Delete Mentor
                </Button>
              )}
            </ButtonGroup>
          </Stack>

          <Data>
            <Data.Item>
              <Data.Label>Email:</Data.Label>
              <Data.Content>{mentor.data.email}</Data.Content>
            </Data.Item>
            {mentor.data.personalEmail ? (
              <Data.Item>
                <Data.Label>Personal Email:</Data.Label>
                <Data.Content>{mentor.data.personalEmail}</Data.Content>
              </Data.Item>
            ) : null}
            {mentor.data.slackUsername ? (
              <Data.Item>
                <Data.Label>Slack Username:</Data.Label>
                <Data.Content>{mentor.data.slackUsername}</Data.Content>
              </Data.Item>
            ) : null}
            {mentor.data.phone ? (
              <Data.Item>
                <Data.Label>Phone Number:</Data.Label>
                <Data.Content>{mentor.data?.phone}</Data.Content>
              </Data.Item>
            ) : null}
            <Data.Item>
              <Data.Label>On Payroll:</Data.Label>
              <Data.Content>
                <UPChip
                  color={mentor.data.onPayroll ? "green" : "grey"}
                  label={mentor.data.onPayroll ? "yes" : "no"}
                />
              </Data.Content>
            </Data.Item>
            <Data.Item>
              <Data.Label>Qualified Catalogue Items:</Data.Label>
              <Data.Content>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                  {mentor.data.qualifiedCatalogueItems?.length ? (
                    mentor.data.qualifiedCatalogueItems
                      .sort(sortByPartnerName)
                      .map((item) => {
                        return (
                          <Chip
                            key={item._id}
                            label={`${item.partner.name} - ${item.name}`}
                            size="small"
                          />
                        );
                      })
                  ) : (
                    <em>none</em>
                  )}
                </Stack>
              </Data.Content>
            </Data.Item>
          </Data>

          <br />

          <EditTextNotepad
            color="purple"
            readonly
            title={`Availability: ${mentor.data.availability}`}
            value={mentor.data.availabilityNotes || "No notes"}
          />

          {confirmDelete ? (
            <DeleteDialog setOpen={setConfirmDelete} mentor={mentor} />
          ) : null}
        </>
      ) : null}

      <br />
      <br />

      {mentor.data ? (
        <InventoryTable assignedInventory={mentor.data.assignedInventory} />
      ) : null}
    </>
  );
}
