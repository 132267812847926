import * as PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router";

import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { SortingTableHead } from "@/components/SortingTableHead";
import { TableCaption } from "@/components/TableCaption";
import clientRoutes from "@/lib/client-routes";
import { getComparator, stableSort } from "@/lib/table-helpers";

import EnrollmentsTableEmptyContent from "./EnrollmentsTableEmptyContent";
import LmsSection from "./LmsSection";
import { columns } from "./table-columns";

const EnrollmentsTable = function ({
  captionTitle,
  enrollments,
  partnerId,
  courseSections,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("studentName");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <TableCaption title={captionTitle} />
      <div>
        <TableContainer>
          <Table size={enrollments.length === 0 ? "small" : "medium"}>
            <SortingTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              className={enrollments.length === 0 ? "empty-table-body" : ""}
            >
              {enrollments.length === 0 ? (
                <EnrollmentsTableEmptyContent />
              ) : null}
              {stableSort(enrollments, getComparator(order, orderBy)).map(
                (row) => {
                  return (
                    <TableRow key={row.student?.email} hover>
                      {columns.map((column) => {
                        if (column.id === "location") {
                          return (
                            <TableCell key={column.id}>
                              {row.location}
                            </TableCell>
                          );
                        }
                        if (column.id === "orderId") {
                          return (
                            <TableCell
                              key={column.id}
                              sx={{
                                position: "relative",
                                "&:hover": {
                                  boxShadow:
                                    "0px 0px 0px 2px inset currentColor",
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              <Link
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  top: 0,
                                  left: 0,
                                }}
                                to={clientRoutes.orders.show(row.order._id)}
                              />
                              <div>Order ID: {row.orderId}</div>
                            </TableCell>
                          );
                        }
                        if (column.id === "studentName") {
                          return (
                            <TableCell
                              key={column.id}
                              sx={{
                                position: "relative",
                                "&:hover": {
                                  boxShadow:
                                    "0px 0px 0px 2px inset currentColor",
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              <Link
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  top: 0,
                                  left: 0,
                                }}
                                to={clientRoutes.profiles.show(
                                  partnerId,
                                  row.student?.email,
                                )}
                              />
                              <div>
                                <strong>{row.studentName}</strong>
                              </div>
                              <div>
                                <span>{row.student?.email}</span>
                              </div>
                            </TableCell>
                          );
                        }
                        if (column.id === "mentor") {
                          return (
                            <TableCell key={column.id}>
                              <Stack
                                gap={1}
                                alignItems={"center"}
                                textAlign={"center"}
                              >
                                <LmsSection
                                  enrollment={row}
                                  courseSections={courseSections}
                                />
                              </Stack>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row[column.id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

EnrollmentsTable.propTypes = {
  captionTitle: PropTypes.string,
  enrollments: PropTypes.array,
};

export default EnrollmentsTable;
