const BASE = import.meta.env.VITE_API_ENDPOINT as string;

const routes = {
  show(partnerId: string, email: string) {
    return `${BASE}/checkout_bff/${partnerId}/${email}`;
  },

  addLineItem(partnerId: string) {
    return `${BASE}/checkout_bff/${partnerId}/items`;
  },

  removeLineItem(partnerId: string, itemId: string) {
    return `${BASE}/checkout_bff/${partnerId}/items/${itemId}`;
  },

  applyCoupon(partnerId: string) {
    return `${BASE}/checkout_bff/${partnerId}/coupons`;
  },

  removeCoupon(partnerId: string, code: string) {
    return `${BASE}/checkout_bff/${partnerId}/coupons/${code}`;
  },

  complete(partnerId: string) {
    return `${BASE}/checkout_bff/${partnerId}/complete`;
  },

  reset(partnerId: string, email: string) {
    return `${BASE}/checkout_bff/${partnerId}/${email}/reset`;
  },
} as const;

export default routes;
