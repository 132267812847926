import { useQuery } from "@tanstack/react-query";

import type { TApplication } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Application from "./";

export function useApplication(
  email: string,
  partnerId: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TApplication, Error> {
  return useQuery({
    queryKey: Application.find.queryKey(email, partnerId),
    queryFn() {
      return Application.find.queryFn(email, partnerId);
    },
    ...options,
  });
}

export function useApplicationTimeline(email: string, partnerId: string) {
  return useQuery(Application.timeline.queryOptions(email, partnerId));
}
