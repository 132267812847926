import { Helmet } from "react-helmet";
import { useDocumentTitle } from "usehooks-ts";

import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";

export default function Insights() {
  useDocumentTitle("Back Office — Insights");

  return (
    <Layout>
      <TitleBar title="Insights" />
      <Layout.Content>
        <Helmet>
          <script
            type="module"
            src="https://analytics.kaplan.com/javascripts/api/tableau.embedding.3.latest.min.js"
          />
        </Helmet>
        <tableau-viz
          id="tableau-viz"
          src="https://analytics.kaplan.com/#/site/UniversityPartnerships/views/WeeklyAppCompletes--Embed/WeeklyAppCompletions"
          width="100%"
          height="863"
          toolbar="bottom"
        ></tableau-viz>
      </Layout.Content>
    </Layout>
  );
}
