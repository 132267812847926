import { MagnifyingGlass } from "phosphor-react";
import { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
//import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";

//import { useDrawer } from "@/components/Drawer";
//import ContactFormDrawer from "@/components/Drawers/ContactFormDrawer";
import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
//import { TitleBarButton } from "@/components/TitleBarButton";
//import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";

import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";
import ContactShow from "./show";

//import type { TContact } from "@/models/contact/types";
import type { PropsWithChildren, ReactNode } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const GetContactInfo = function ({
  children,
}: {
  children: (params: Record<string, string>) => ReactNode;
}) {
  const params = useParams();
  return children({ ...params });
};

const ContactsLayout = function ({
  children,
  submitSearch,
}: PropsWithChildren<{
  submitSearch: (data: { id: string }) => void;
}>) {
  useDocumentTitle("Back Office — Contacts");

  //const contactFormDrawer = useDrawer(ContactFormDrawer);
  //const showAlert = useAlert();
  //const navigateTo = useNavigate();
  const params = useParams();
  const [showBulkCreate, setShowBulkCreate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // const handleNew = function () {
  //   contactFormDrawer.show({
  //     cancel() {
  //       contactFormDrawer.remove();
  //     },
  //     successCallback(data: TContact) {
  //       showAlert({ message: "Contact created!" });

  //       contactFormDrawer.remove();
  //       navigateTo(clientRoutes.contacts.show(data.contact_id));
  //     },
  //   });
  // };

  return (
    <>
      <TitleBar title="Contacts">
        {/* <TitleBarButton
          startIcon={<UploadFileIcon />}
          onClick={() => {
            setShowBulkCreate(true);
          }}
        >
          Bulk Create
        </TitleBarButton>
        <TitleBarButton icon="add" onClick={handleNew}>
          New Contact
        </TitleBarButton> */}
      </TitleBar>
      <SearchForm query={params.query} onSubmit={submitSearch} />
      <Layout.Content>
        {children}
        <Dialog
          open={showBulkCreate}
          onClose={() => {
            setShowBulkCreate(false);
          }}
        >
          <DialogTitle>Bulk Create Contacts</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Explicabo commodi expedita ut illum deleniti. Doloremque deleniti
              aut fugiat sit culpa. Qui qui quisquam sunt numquam similique vero
              voluptatem dolore.
            </DialogContentText>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "0.75rem",
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Select file
                <VisuallyHiddenInput
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  type="file"
                />
              </Button>
              {selectedFile?.name ? selectedFile.name : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="neutral"
              onClick={() => {
                setShowBulkCreate(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setShowBulkCreate(false);
              }}
              variant="contained"
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Layout.Content>
    </>
  );
};

export default function ProfilesIndexRoute() {
  const navigateTo = useNavigate();
  const submitSearch = (data) => {
    navigateTo(clientRoutes.contacts.results(data));
  };

  return (
    <Layout>
      <>
        <Routes>
          <Route
            index
            element={
              <ContactsLayout submitSearch={submitSearch}>
                <EmptyIndexMessage
                  icon={<MagnifyingGlass size={100} weight="thin" />}
                  text="Perform a search to view a contact"
                />
              </ContactsLayout>
            }
          />
          <Route
            element={
              <ContactsLayout submitSearch={submitSearch}>
                <SearchResults />
              </ContactsLayout>
            }
            path={clientRoutes.contacts.resultsPath}
          />
          <Route
            element={
              <ContactsLayout submitSearch={submitSearch}>
                <GetContactInfo>
                  {({ _id }) => <ContactShow id={_id} />}
                </GetContactInfo>
              </ContactsLayout>
            }
            path={clientRoutes.contacts.showPath}
          />
        </Routes>
      </>
    </Layout>
  );
}
