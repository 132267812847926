import { useMutation, useQueryClient } from "@tanstack/react-query";

import Box from "@mui/material/Box";

import { Drawer, DrawerManager, useDrawer } from "@/components/Drawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import { PS_COUPON_DEFAULTS } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import { useApplication } from "@/models/application/hooks";
import { useProfile } from "@/models/profile/hooks";
import Scholarship from "@/models/scholarship";
import { useScholarship } from "@/models/scholarship/hooks";
import { ScholarshipShowView } from "@/routes/Scholarships/components/ShowView";

import type { TCoupon } from "@/models/coupon/types";

export default DrawerManager.create(function ({
  email,
  partnerId,
  successCallback,
}: {
  email: string;
  partnerId: string;
  successCallback?: (data: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const applicationQuery = useApplication(email, partnerId);
  const scholarshipQuery = useScholarship(email, partnerId);
  const profileQuery = useProfile(email, partnerId);

  const updateMutation = useMutation({
    mutationFn: Scholarship.save,
    onSuccess(savedData) {
      showAlert({
        message: `Scholarship updated`,
      });
      queryClient.invalidateQueries({
        queryKey: Scholarship.findAll.queryKey(),
      });
      if (savedData.status === "approved") {
        couponFormDrawer.show({
          cancel() {
            couponFormDrawer.remove();
          },
          data: {
            ...PS_COUPON_DEFAULTS,
            partner: {
              _id: scholarshipQuery.data.partner._id,
              name: scholarshipQuery.data.partner.name,
            },
            scopedUsers: [profileQuery.data.email],
          },
          errorCallback(error) {
            if (error instanceof Error && error.message.includes("409")) {
              showAlert({
                message: `Error! This coupon code already exists!`,
                severity: "error",
                duration: 10000,
              });
            } else {
              showAlert({
                message: `Error! An error has ocurred`,
                severity: "error",
                duration: 10000,
              });
            }
          },
          successCallback(data: TCoupon) {
            showAlert({
              message: `Successfully created coupon with code: ${data.code}`,
              duration: null,
            });
            console.log(data);
            couponFormDrawer.remove();
          },
        });
      }
      if (successCallback) {
        successCallback(savedData);
      }
    },
  });

  return (
    <Drawer
      loading={
        applicationQuery.isLoading ||
        profileQuery.isLoading ||
        scholarshipQuery.isLoading ||
        updateMutation.isPending
      }
      title="Scholarship"
    >
      {profileQuery.data && (
        <Box sx={{ p: 2, bgcolor: "background.paper" }}>
          {scholarshipQuery.isPending && <div>Loading...</div>}
          {scholarshipQuery.data && (
            <ScholarshipShowView
              email={profileQuery.data.email}
              partnerId={profileQuery.data.partner._id}
              applicationQuery={applicationQuery}
              isSaving={updateMutation.isPending}
              profileQuery={profileQuery}
              scholarshipQuery={scholarshipQuery}
              saveScholarship={updateMutation.mutate}
            />
          )}
        </Box>
      )}
    </Drawer>
  );
});
