import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

import UPDialog from "@/components/UPDialog";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import Mentor from "@/models/mentor";

export default function DeleteDialog({ setOpen, mentor = {} }) {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: Mentor.deleteMentor,
    onSuccess() {
      showAlert({ message: "Mentor deleted successfully" });
      queryClient.invalidateQueries({
        queryKey: Mentor.findAll.queryKey(),
      });
      navigate(clientRoutes.mentorSearch.email.index());
    },
    onError() {
      showAlert({ message: "Mentor could not be deleted", severity: "error" });
    },
  });

  return (
    <UPDialog
      close={() => {
        setOpen(false);
      }}
      Content="You cannot undo this action."
      Icon={DeleteOutlinedIcon}
      Title="Are you sure you want to delete this mentor?"
      Actions={
        <>
          <Button
            color="neutral"
            onClick={() => {
              setOpen(false);
            }}
            variant="outlined"
          >
            Never mind
          </Button>
          <LoadingButton
            color="error"
            loading={deleteMutation.isPending}
            onClick={() => deleteMutation.mutate(mentor.data)}
            variant="contained"
            sx={{ minWidth: "125px" }}
          >
            Yes, Delete Mentor
          </LoadingButton>
        </>
      }
    />
  );
}
