import { useQuery } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Student } from "phosphor-react";
import { useParams } from "react-router";

import LinearProgress from "@mui/material/LinearProgress";

import SearchMessage from "@/components/SearchMessage";
import Profile from "@/models/profile";

import Results from "./Results";

export const SearchResults = () => {
  const params = useParams();
  const profiles = useQuery({
    queryKey: Profile.findAllByEmail.queryKey(params.email),
    queryFn() {
      return Profile.findAllByEmail.queryFn(params.email);
    },
  });

  if (profiles.isError) {
    return (
      <SearchMessage
        icon={Student}
        text={
          profiles.error instanceof HTTPError &&
          profiles.error.response.status === 404
            ? "Profile could not be found"
            : "An error has occurred"
        }
      />
    );
  }

  if (profiles.isLoading) {
    return <LinearProgress color="secondary" />;
  }

  return profiles?.data ? <Results profiles={profiles.data} /> : null;
};
