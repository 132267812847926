import { Link } from "react-router";

import { Spinner } from "@/components/Spinner";
import { UPChip } from "@/components/UPChip";
import clientRoutes from "@/lib/client-routes";
import { useMentorsAssignedToInventoryItem } from "@/models/mentor/hooks";

export default function MentorsTableCellContents({ inventoryItem }) {
  const mentors = useMentorsAssignedToInventoryItem(inventoryItem._id);
  return mentors.isLoading ? (
    <Spinner loading={true} />
  ) : mentors.isError ? (
    <em>Could not load mentors</em>
  ) : (
    mentors.data?.map((mentor, i) => (
      <UPChip
        component={Link}
        to={clientRoutes.mentorSearch.email.show(mentor.email)}
        key={i}
        label={`${mentor.firstName} ${mentor.lastName}`}
        size="small"
        sx={{ cursor: "pointer" }}
      />
    ))
  );
}
