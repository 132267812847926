import { Link } from "react-router";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";
import { useCatalogueItem } from "@/models/catalogue/hooks";

function InventoryItemName({ _id }) {
  const to = clientRoutes.catalogue.show(_id);

  const catalogueItem = useCatalogueItem(_id, {
    suspense: true,
  });

  const ListItemSX = {
    borderRadius: 2,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
  };

  const title = catalogueItem.data
    ? catalogueItem.data.name
    : `Catalogue Item ${catalogueItem.status}`;

  return (
    <ListItemButton component={Link} to={to} sx={ListItemSX}>
      <ListItemText
        primaryTypographyProps={{ color: "primary" }}
        primary={catalogueItem.data && title}
      />
    </ListItemButton>
  );
}

export default InventoryItemName;
