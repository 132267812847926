import { Flag, Warning } from "phosphor-react";
import { useParams } from "react-router";

import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import { ApplicationTimelineDrawer } from "@/components/Drawers/ApplicationTimelineDrawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import ScholarshipDrawer from "@/components/Drawers/ScholarshipDrawer";
import { UPChip } from "@/components/UPChip";
import { STATUS_COLOR_MAP, STATUS_MAP } from "@/constants";
import { formatDate } from "@/lib/helpers";

import { AcademicDetails } from "./AppDisplayComponents/AcademicDetails";
import { ChangeApplicationStatus } from "./AppDisplayComponents/ChangeApplicationStatus";
import { EssayAndFileTab } from "./AppDisplayComponents/EssayAndFileTab";
import { GuardianDetails } from "./AppDisplayComponents/GuardianDetails";
import { InventoryItemDetails } from "./AppDisplayComponents/InventoryItemDetails";
import { StudentLegalAndAddress } from "./AppDisplayComponents/StudentLegalAndAddress";
import { StudentNameAndContact } from "./AppDisplayComponents/StudentNameAndContact";

import type { TApplication, TUseApplication } from "@/models/application/types";
import type { TUseProfile } from "@/models/profile/types";
import type { TScholarship } from "@/models/scholarship/types";
import type { UseMutateFunction, UseQueryResult } from "@tanstack/react-query";

export default function ApplicationShowView({
  applicationQuery,
  isSaving,
  profileQuery,
  scholarshipQuery,
  saveApplication,
}: {
  applicationQuery: TUseApplication;
  isSaving: boolean;
  profileQuery: TUseProfile;
  saveApplication: UseMutateFunction<
    TApplication,
    Error,
    TApplication,
    unknown
  >;
  scholarshipQuery: UseQueryResult<TScholarship, Error>;
}) {
  const profileDrawer = useDrawer(ProfileDrawer);
  const scholarshipDrawer = useDrawer(ScholarshipDrawer);
  const timelineDrawer = useDrawer(ApplicationTimelineDrawer);
  const { partner, email } = useParams();

  const cohorts = applicationQuery.data?.selections.map((sel, index) => {
    return (
      <InventoryItemDetails inventoryId={sel.inventoryItem._id} key={index} />
    );
  });

  const showScholarship = function () {
    scholarshipDrawer.show({
      email,
      partnerId: partner,
      successCallback() {
        scholarshipDrawer.remove();
      },
    });
  };

  const showTimeline = function () {
    timelineDrawer.show({
      email: email || profileQuery.data.email || scholarshipQuery.data.author,
      partnerId:
        partner ||
        profileQuery.data.partner._id ||
        scholarshipQuery.data.partner._id,
    });
  };

  return (
    <div>
      <Typography
        component="h2"
        display="flex"
        variant="h5"
        sx={{ alignItems: "center" }}
      >
        {profileQuery.isLoading ? (
          <>
            <span style={{ opacity: 0.5 }}>Loading applicant</span>
            <IconButton style={{ opacity: 0 }} onClick={() => {}}>
              <VisibilityIcon />
            </IconButton>
          </>
        ) : profileQuery.data ? (
          <>
            {`${profileQuery.data.firstName} ${profileQuery.data.lastName}`}
            <IconButton
              onClick={() => {
                profileDrawer.show({
                  email: profileQuery.data.email,
                  partnerID: profileQuery.data.partner._id,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        ) : null}
      </Typography>

      <Typography component="h2" display="block" marginBottom={1} variant="h5">
        {applicationQuery.data?.partner.name}
      </Typography>

      {applicationQuery.data?.flagged ? (
        <div>
          {applicationQuery.data?.flagSeverity === "red" ? (
            <Flag
              color="#d00"
              size="24"
              style={{ marginRight: "0.5rem" }}
              weight="fill"
            />
          ) : (
            <Warning
              color="#FFB81C"
              size="24"
              style={{ marginRight: "0.5rem" }}
              weight="fill"
            />
          )}
        </div>
      ) : null}

      <br />

      <Data>
        <Data.Item>
          <Data.Label>Application Status:</Data.Label>
          <Data.Content>
            {applicationQuery.data ? (
              <UPChip
                color={STATUS_COLOR_MAP[applicationQuery.data.status]}
                label={STATUS_MAP[applicationQuery.data.status]}
                size="small"
              />
            ) : (
              <em>none</em>
            )}
          </Data.Content>
        </Data.Item>
        <Data.Item>
          <Data.Label>Scholarship Status:</Data.Label>
          <Data.Content>
            {scholarshipQuery.data ? (
              <UPChip
                color={STATUS_COLOR_MAP[scholarshipQuery.data.status]}
                label={STATUS_MAP[scholarshipQuery.data.status]}
                size="small"
              />
            ) : (
              <em>none</em>
            )}
          </Data.Content>
          {scholarshipQuery.data ? (
            <IconButton onClick={showScholarship} size="small">
              <VisibilityIcon />
            </IconButton>
          ) : null}
        </Data.Item>
        <Data.Item>
          <Data.Label>Timeline:</Data.Label>
          <Data.Content>
            <IconButton onClick={showTimeline} size="small">
              <VisibilityIcon />
            </IconButton>
          </Data.Content>
        </Data.Item>
        {applicationQuery.data?.completedAt ? (
          <Data.Item>
            <Data.Label>Application Completed:</Data.Label>
            <Data.Content>
              {formatDate(applicationQuery.data.completedAt)}
            </Data.Content>
          </Data.Item>
        ) : null}
      </Data>

      <br />

      {cohorts ? (
        <Stack spacing={2} marginBottom={2}>
          {cohorts}
        </Stack>
      ) : null}

      <Typography variant="h6" sx={{ mb: 2 }}>
        Application Status
      </Typography>
      {applicationQuery.data && (
        <ChangeApplicationStatus
          application={applicationQuery.data}
          isSaving={isSaving}
          save={saveApplication}
        />
      )}

      <br />
      <br />

      {applicationQuery.data?.student ? (
        <>
          <StudentNameAndContact student={applicationQuery.data.student} />
          <EssayAndFileTab
            appData={applicationQuery.data}
            isSaving={isSaving}
            save={saveApplication}
          />

          <br />
          <br />

          <StudentLegalAndAddress student={applicationQuery.data.student} />
          <br />
          <br />
        </>
      ) : null}

      {applicationQuery.data?.guardian ? (
        <>
          <GuardianDetails guardian={applicationQuery.data.guardian} />
          <br />
          <br />
        </>
      ) : (
        <em>No guardian information</em>
      )}

      {applicationQuery.data?.education ? (
        <AcademicDetails education={applicationQuery.data.education} />
      ) : (
        <div>
          <em>No academic information</em>
        </div>
      )}
    </div>
  );
}
