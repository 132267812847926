import { Link } from "react-router";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";

function PartnerName({ partner }) {
  const url = clientRoutes.partners.show(partner._id);

  const ListItemSX = {
    borderRadius: 2,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
  };

  return (
    <ListItemButton component={Link} to={url} sx={ListItemSX}>
      <ListItemText
        primaryTypographyProps={{ color: "primary" }}
        primary={partner.name}
      />
    </ListItemButton>
  );
}

export default PartnerName;
