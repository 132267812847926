import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import LoadingButton from "@mui/lab/LoadingButton";

import FormErrorMessage from "@/components/FormErrorMessage";
import UPDialog from "@/components/UPDialog";
import { useAlert } from "@/hooks/use-alert";
import Contact from "@/models/contact";

export default function ForgetDialog({ close, id }) {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <UPDialog
      close={close}
      Icon={EmojiPeopleIcon}
      Title="Are you sure you want to permanently forget this contact?"
      Content="You cannot undo this action."
      Actions={
        <>
          <LoadingButton
            color="neutral"
            loading={isLoading}
            onClick={close}
            variant="outlined"
            sx={{ minWidth: 140 }}
          >
            Never mind
          </LoadingButton>
          <LoadingButton
            color="error"
            loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await Contact.forgetByID(id);
                setIsLoading(false);
                queryClient.invalidateQueries({
                  queryKey: ["contact", id],
                });
                showAlert({ message: "Contact forgotten" });
                close();
              } catch (err) {
                setIsLoading(false);
                setErrorMessage(err.message);
              }
            }}
            variant="contained"
          >
            Yes, Forget Contact
          </LoadingButton>
          {errorMessage ? (
            <FormErrorMessage
              text={errorMessage}
              sx={{ flexBasis: "100%", mt: 1, justifyContent: "center" }}
            />
          ) : null}
        </>
      }
    />
  );
}
