import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import Heading from "../Heading";

import type { TApplication } from "@/models/application/types";

export const GuardianDetails = ({
  guardian,
}: {
  guardian: TApplication["guardian"];
}) => {
  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Guardian Information
      </Typography>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>First Name</Heading>
          {guardian.firstName}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Last Name</Heading>
          {guardian.lastName}
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Guardian Email</Heading>
          {guardian.email}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Guardian Phone Number</Heading>
          {guardian.phone}
        </Grid>
      </Grid>
    </>
  );
};
