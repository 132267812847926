import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function SearchForm({ onSubmit, query = "" }) {
  const handleSubmit = function (event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    onSubmit(data.get("query"));
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{ display: { sm: "flex" }, p: 2, gap: 1, alignItems: "flex-start" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            InputLabelProps={{ shrink: true }}
            name="query"
            size="small"
            defaultValue={query}
            variant="outlined"
            fullWidth={true}
          />
        </Box>

        <Button
          color="neutral"
          type="submit"
          variant="outlined"
          sx={{ flexShrink: 0 }}
        >
          Search
        </Button>
      </Box>
    </form>
  );
}
