import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import type { TScholarship } from "./types";
import type { UseQueryOptions } from "@tanstack/react-query";

import Scholarship from "./";

export function useScholarship(
  email: string,
  partnerId: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
) {
  return useQuery({
    ...Scholarship.find.queryOptions(email, partnerId),
    ...options,
  });
}

export function useAllScholarshipsByStatus(status: TScholarship["status"]) {
  return useInfiniteQuery(Scholarship.findAllByStatus.queryOptions(status));
}

export function useScholarshipTimeline(email: string, partnerId: string) {
  return useQuery(Scholarship.timeline.queryOptions(email, partnerId));
}
