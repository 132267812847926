import { CurrencyDollar } from "phosphor-react";
import { useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { MainDetail } from "@/components/MainDetail";
import { STATUS_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import { useAllScholarshipsByStatus } from "@/models/scholarship/hooks";

import { SideBar } from "./components/Sidebar";
import { ScholarshipShow } from "./show";

import type { TScholarshipStatus } from "@/models/scholarship/types";

export default function ScholarshipsRoute() {
  useDocumentTitle("Back Office — Scholarships");
  const { status } = useParams();
  const filterStatus = status as TScholarshipStatus;
  const query = useAllScholarshipsByStatus(filterStatus);

  return (
    <MainDetail
      MainComponent={SideBar}
      mainProps={{
        scholarshipsQuery: query,
        supTitle: STATUS_MAP[filterStatus],
      }}
      DetailComponent={ScholarshipShow}
      detailPath={clientRoutes.scholarships.showPath}
      EmptyComponent={() => {
        const isEmpty =
          query.status !== "error" &&
          query.status !== "pending" &&
          query.data?.pages?.length &&
          query.data.pages[0].results.length === 0;

        const text = function () {
          if (query.status === "error") {
            return "Error loading inbox";
          }
          if (query.status === "pending") {
            return "Inbox Loading";
          }
          if (isEmpty) {
            return "Inbox Empty!";
          }

          return "Select a scholarship";
        };

        return (
          <EmptyIndexMessage
            celebrate={isEmpty}
            icon={<CurrencyDollar size={100} weight="thin" />}
            text={text()}
          />
        );
      }}
    />
  );
}
