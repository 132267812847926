import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useDrawer } from "@/components/Drawer";
import EnrollmentSwitchDrawer from "@/components/Drawers/EnrollmentSwitchDrawer";
import { useAlert } from "@/hooks/use-alert";
import EnrollmentModel from "@/models/enrollment";

import ConfirmCancel from "./ConfirmCancel";
import ConfirmWithdraw from "./ConfirmWithdraw";

export default function Enrollment({ enrollment, email, onMutationSuccess }) {
  const theme = useTheme();
  const enrollmentSwitchDrawer = useDrawer(EnrollmentSwitchDrawer);
  const showAlert = useAlert();
  const [openDialog, setOpenDialog] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);

  const { order, inventory } = enrollment;

  const dropMutation = useMutation({
    mutationFn() {
      return EnrollmentModel.deleteEnrollment(order, inventory._id, email);
    },
    onSuccess() {
      setOpenDialog(false);
      showAlert({
        message: `Successfully dropped enrollment`,
        duration: null,
      });
      onMutationSuccess();
    },
    onError() {
      showAlert({
        message: `An Error Has Occurred`,
        severity: "error",
      });
    },
  });

  const withdrawMutation = useMutation({
    mutationFn() {
      return EnrollmentModel.withdrawEnrollment(
        enrollment,
        inventory._id,
        email,
      );
    },
    onSuccess() {
      setOpenWithdrawDialog(false);
      showAlert({
        message: `Successfully withdrew enrollment`,
        duration: null,
      });
      onMutationSuccess();
    },
    onError() {
      showAlert({
        message: `An Error Has Occurred`,
        severity: "error",
      });
    },
  });

  const section = useQuery({
    queryFn() {
      return EnrollmentModel.fetchLmsEnrollment.queryFn({
        partnerId: inventory.partner._id,
        inventoryId: inventory._id,
        email,
      });
    },
    queryKey: EnrollmentModel.fetchLmsEnrollment.queryKey({
      partnerId: inventory.partner._id,
      inventoryId: inventory._id,
      email,
    }),
  });

  return (
    inventory && (
      <Box
        display="flex"
        sx={{
          py: 2,
          justifyContent: "space-between",
          gap: ".5em",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.grey[300],
          ...theme.applyStyles("dark", {
            borderColor: theme.palette.grey[700],
          }),
        }}
      >
        <Link
          to={`/inventory-search/${inventory._id}`}
          style={{
            textDecoration: "none",
            color: "inherit",
            width: "45%",
          }}
        >
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            {inventory?.name}
          </Typography>
          <Typography variant="body1">
            <strong>{inventory.catalogueItem?.name}</strong>
          </Typography>
          <Typography
            variant="body2"
            sx={{ opacity: 0.7 }}
          >{`Order ID: ${order?._id}`}</Typography>
          {enrollment.withdrawnAt && (
            <Typography variant="body1">
              <strong>
                WITHDRAWN (
                {new Date(enrollment.withdrawnAt).toLocaleDateString()})
              </strong>
            </Typography>
          )}
        </Link>
        <Divider orientation="vertical" flexItem />
        <Stack alignItems="flex-start" spacing={1} paddingLeft={2}>
          <Typography variant="body1">
            <strong>
              Assigned mentor:{" "}
              {section.isPending
                ? "Loading..."
                : section.status === "success" && section.data.name}
            </strong>
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={1} paddingRight={1} style={{ flexShrink: 0 }}>
          <Button
            color="secondary"
            onClick={() =>
              enrollmentSwitchDrawer.show({
                cancel() {
                  enrollmentSwitchDrawer.remove();
                },
                email,
                lineItem: inventory,
                successCallback() {
                  onMutationSuccess();
                  enrollmentSwitchDrawer.remove();
                  showAlert({
                    message: `Enrollment changed successfully`,
                  });
                },
              })
            }
            startIcon={<SwapHorizIcon />}
            variant="outlined"
          >
            Switch Enrollment
          </Button>
          {inventory && order && (
            <Button
              color="secondary"
              onClick={() => setOpenDialog(true)}
              size="small"
              startIcon={<DoDisturbIcon />}
              variant="outlined"
            >
              Drop Enrollment
            </Button>
          )}
          {inventory.catalogueItem.type === "collegeCredit" &&
            !enrollment.withdrawnAt && (
              <Button
                color="error"
                onClick={() => setOpenWithdrawDialog(true)}
                size="small"
                startIcon={<RemoveCircleOutline />}
                variant="outlined"
              >
                Withdraw Enrollment
              </Button>
            )}
        </Stack>
        {openDialog ? (
          <ConfirmCancel
            mutation={dropMutation}
            isPending={dropMutation.isPending}
            handleClose={() => setOpenDialog(false)}
          />
        ) : null}
        {openWithdrawDialog ? (
          <ConfirmWithdraw
            mutation={withdrawMutation}
            handleClose={() => setOpenWithdrawDialog(false)}
          />
        ) : null}
      </Box>
    )
  );
}
