import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";

import routes from "./routes";

import type {
  TScholarship,
  TScholarshipFindAllByStatusResponse,
  TScholarshipTimelineEvent,
} from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const findAll = {
  queryKey() {
    return ["scholarships"] as const;
  },
};

const findAllByStatus = {
  queryOptions(status: TScholarship["status"]) {
    return infiniteQueryOptions({
      queryKey: ["scholarships", status] as const,
      queryFn: async function (context) {
        const cursor = context.pageParam;
        const url = routes.status(status);
        return await window.auth.client
          .post(url, {
            json: {
              cursor,
            },
          })
          .json<TScholarshipFindAllByStatusResponse>();
      },
      getNextPageParam: (lastPage) => lastPage.cursor,
      initialPageParam: 0,
    });
  },
};

const timeline = {
  queryOptions(email: string, partnerId: string) {
    return queryOptions({
      queryKey: ["scholarship", email, partnerId, "timeline"] as const,
      queryFn: async function () {
        const url = routes.timeline(email, partnerId);
        return await window.auth.client
          .get(url)
          .json<TScholarshipTimelineEvent[]>();
      },
    });
  },
};

const find = {
  queryOptions(email: string, partnerId: string) {
    return queryOptions({
      queryKey: ["scholarship", email, partnerId] as const,
      queryFn: async function () {
        const url = routes.show(email, partnerId);
        return await window.auth.client.get(url).json<TScholarship>();
      },
    });
  },
};

/* Actions
/* ------------------------------------------------------------------ */

async function save(data: TScholarship) {
  const url = routes.patch();
  return await window.auth.client
    .patch(url, {
      json: data,
    })
    .json<TScholarship>();
}

export default {
  find,
  findAll,
  findAllByStatus,
  timeline,

  save,
};
