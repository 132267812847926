import { Link } from "react-router";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const LineItemStack = ({ lineItems }) => {
  const theme = useTheme();

  return (
    <div>
      {lineItems.map((lineItem, index) => {
        return (
          <Box
            display="flex"
            key={`${lineItem._id + index}`}
            sx={{
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
              borderBottomColor: theme.palette.grey[300],
              ...theme.applyStyles("dark", {
                borderBottomColor: theme.palette.grey[700],
              }),
              flexWrap: "wrap",
              py: 2,
            }}
          >
            <Link
              to={`/inventory-search/${lineItem._id}`}
              style={{
                color: "inherit",
                cursor: "pointer",
                textDecoration: "none",
                flexGrow: 1,
              }}
            >
              <strong>{lineItem.catalogueItem.name}</strong>
              <br />
              {lineItem.name}
              <br />
              {lineItem.formattedPriceToPay}
            </Link>
            <br />
          </Box>
        );
      })}
    </div>
  );
};

export default LineItemStack;
