import requestOptions from "@/lib/request-options";

import routes from "./routes";

import type { TPartner } from "./types";

/* Helpers
/* ------------------------------------------------------------------ */

function propertiesOrDefaults(data: Partial<TPartner>) {
  return {
    ...data,
    canvasAccountId: data.canvasAccountId || "",
    canvasAdminToken: data.canvasAdminToken || "",
    domain: data.domain || "",
    linkLMS: data.linkLMS || "",
    linkSiteRebuild: data.linkSiteRebuild || "",
    name: data.name || "",
    onlineName: data.onlineName || "",
    published: data.published || false,
    supportEmail: data.supportEmail || "",
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findAll = {
  queryFn: async function () {
    const data = await window.auth.client
      .get(routes.index())
      .json<TPartner[]>();
    return data.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  },
  queryKey() {
    return ["partners"] as const;
  },
};

const findById = {
  queryFn: async function (id: string) {
    return await window.auth.client.get(routes.show(id)).json<TPartner>();
  },
  queryKey(id: string) {
    return ["partner", id] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

async function save(data: TPartner) {
  const { url, method } = requestOptions(routes, data._id);
  return await window.auth.client[method](url, {
    json: data,
  }).json<TPartner>();
}

export default {
  propertiesOrDefaults,

  findAll,
  findById,

  save,
};
