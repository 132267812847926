import { HTTPError } from "ky";
import { Student } from "phosphor-react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import { ApplicationDrawer } from "@/components/Drawers/ApplicationDrawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import ScholarshipDrawer from "@/components/Drawers/ScholarshipDrawer";
import SearchMessage from "@/components/SearchMessage";
import { UPChip } from "@/components/UPChip";
import { PS_COUPON_DEFAULTS, STATUS_COLOR_MAP, STATUS_MAP } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import { useProfile } from "@/models/profile/hooks";

import ProfileTabs from "./components/ProfileTabs";

import type { TCoupon } from "@/models/coupon/types";

export const ProfileShow = function ({
  email,
  partnerID,
}: {
  email: string;
  partnerID?: string;
}) {
  const profileQuery = useProfile(email, partnerID);
  const applicationDrawer = useDrawer(ApplicationDrawer);
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const scholarshipDrawer = useDrawer(ScholarshipDrawer);
  const showAlert = useAlert();

  const showApplication = function () {
    applicationDrawer.show({
      cancelApplicationSuccessCallback() {
        applicationDrawer.remove();
        profileQuery.refetch();
      },
      email,
      partnerId: partnerID,
      successCallback() {
        showAlert({
          message: `Application updated`,
        });
        applicationDrawer.remove();
      },
    });
  };

  const showScholarship = function () {
    scholarshipDrawer.show({
      email,
      partnerId: partnerID,
      successCallback() {
        scholarshipDrawer.remove();
      },
    });
  };

  if (profileQuery.isError) {
    return (
      <SearchMessage
        icon={Student}
        text={
          profileQuery.error instanceof HTTPError &&
          profileQuery.error.response?.status === 404
            ? "Profile could not be found"
            : "An error has occurred"
        }
      />
    );
  }

  if (profileQuery.isLoading) {
    return <LinearProgress color="secondary" />;
  }

  if (profileQuery.data) {
    return (
      <>
        {profileQuery.isLoading ? (
          <LinearProgress
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0, left: 0 }}
          />
        ) : null}
        <br />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
          sx={{ overflow: "hidden" }}
        >
          <div style={{ flexGrow: 1 }}>
            <Typography variant="h5">
              {profileQuery.data.firstName + " " + profileQuery.data.lastName}
              <br />
              {profileQuery.data.email}
              <br />
              {profileQuery.data.partner?.name ??
                "TODO: where is partner name?"}
            </Typography>
          </div>
          <Button
            color="secondary"
            onClick={() => {
              couponFormDrawer.show({
                cancel() {
                  couponFormDrawer.remove();
                },
                data: {
                  ...PS_COUPON_DEFAULTS,
                  code: "",
                  scopedUsers: [profileQuery.data.email],
                  partner: {
                    _id: profileQuery.data.partner._id,
                    name: profileQuery.data.partner.name,
                  },
                },
                errorCallback(error) {
                  if (error instanceof Error && error.message.includes("409")) {
                    showAlert({
                      message: `Error! This coupon code already exists!`,
                      severity: "error",
                      duration: 10000,
                    });
                  } else {
                    showAlert({
                      message: `Error! An error has ocurred`,
                      severity: "error",
                      duration: 10000,
                    });
                  }
                },
                successCallback(data: TCoupon) {
                  showAlert({
                    message: `Successfully created coupon with code: ${data.code}`,
                    duration: null,
                  });
                  couponFormDrawer.remove();
                },
              });
            }}
            variant="outlined"
          >
            New Coupon
          </Button>
        </Stack>

        <br />

        <Data>
          <Data.Item>
            <Data.Label>Profile type</Data.Label>
            <Data.Content>{profileQuery.data.type}</Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Application</Data.Label>
            <Data.Content>
              {profileQuery.data?.applicationStatus ? (
                <UPChip
                  color={STATUS_COLOR_MAP[profileQuery.data.applicationStatus]}
                  label={STATUS_MAP[profileQuery.data.applicationStatus]}
                  size="small"
                />
              ) : (
                <em>none</em>
              )}
            </Data.Content>
            {profileQuery.data.applicationStatus && (
              <IconButton onClick={showApplication} size="small">
                <VisibilityIcon />
              </IconButton>
            )}
          </Data.Item>
          <Data.Item>
            <Data.Label>Scholarship</Data.Label>
            <Data.Content>
              {profileQuery.data?.scholarshipStatus ? (
                <UPChip
                  color={STATUS_COLOR_MAP[profileQuery.data.scholarshipStatus]}
                  label={STATUS_MAP[profileQuery.data.scholarshipStatus]}
                  size="small"
                />
              ) : (
                <em>none</em>
              )}
            </Data.Content>
            {profileQuery.data.scholarshipStatus && (
              <IconButton onClick={showScholarship} size="small">
                <VisibilityIcon />
              </IconButton>
            )}
          </Data.Item>
        </Data>

        {Boolean(profileQuery.data?.certificates?.length) && (
          <>
            <Typography display="block" variant="h6">
              <span style={{ fontWeight: 500 }}>Certificate links:</span>
            </Typography>
            <Box display="flex">
              {profileQuery.data.certificates.map((c, i) => (
                <Link
                  key={i}
                  display="block"
                  href={c}
                  noWrap
                  sx={{ marginRight: 1 }}
                  underline="always"
                  variant="body1"
                >
                  {c}
                </Link>
              ))}
            </Box>
          </>
        )}
        {Boolean(profileQuery.data?.certificatesData?.length) && (
          <>
            <Typography display="block" variant="h6">
              <span style={{ fontWeight: 500 }}>Certificates by Course:</span>
            </Typography>
            <Box display="flex">
              {profileQuery.data.certificatesData.map((c, i) => (
                <Link
                  key={i}
                  display="block"
                  href={c.url}
                  noWrap
                  sx={{ marginRight: 1 }}
                  underline="always"
                  variant="body1"
                >
                  {c.inventory?.name}
                  <br />
                  {c.inventory?.startDate}
                </Link>
              ))}
            </Box>
          </>
        )}

        <br />

        <ProfileTabs
          email={email}
          partnerID={partnerID}
          profile={profileQuery}
        />
      </>
    );
  }
};
