import { useNavigate } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { useDrawer } from "@/components/Drawer";
import CouponFormDrawer from "@/components/Drawers/CouponFormDrawer";
import { MainDetail } from "@/components/MainDetail";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import { useCoupons } from "@/models/coupon/hooks";

import Empty from "./components/Empty";
import SideBar from "./components/SideBar/SideBar";
import CouponShow from "./show";

const CouponsRoute = function CouponsRoute() {
  useDocumentTitle("Back Office — Coupons");

  const couponFormDrawer = useDrawer(CouponFormDrawer);
  const showAlert = useAlert();
  const navigate = useNavigate();
  const coupons = useCoupons();

  const showNewForm = function () {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: {},
      errorCallback(error) {
        if (error.message.includes("409")) {
          showAlert({
            message: `Error! This coupon code already exists!`,
            severity: "error",
            duration: 10000,
          });
        } else {
          showAlert({
            message: `An error has ocurred!`,
            severity: "error",
            duration: 10000,
          });
        }
      },
      successCallback(data) {
        coupons.refetch();
        showAlert({
          message: `Successfully created coupon with code: ${data.code}`,
          duration: null,
        });
        couponFormDrawer.remove();
        navigate(
          clientRoutes.coupons.show(data.code, {
            state: {
              coupon: data,
            },
          }),
        );
      },
    });
  };

  return (
    <>
      <MainDetail
        MainComponent={SideBar}
        mainProps={{
          showNewForm,
          coupons,
        }}
        DetailComponent={CouponShow}
        detailPath={clientRoutes.coupons.showPath}
        EmptyComponent={Empty}
      />
    </>
  );
};

export default CouponsRoute;
