import type { TScholarship } from "@/models/scholarship/types";
import type { Entries } from "@/type-helpers";

const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

const DESIRED_KEYS = [
  "lunchProgram",
  "snap",
  "publicHousing",
  "medicaid",
  "ssdi",
  "satRegistrationFeeWaiver",
  "pellGrant",
  "otherBenefitsUS",
  "otherBenefitsNonUS",
  "privateSchoolAssistance",
] as const;

export const getScore = (scholarshipItem: TScholarship) => {
  const entries = getEntries(scholarshipItem);
  return entries.reduce((previousValue, [key, val]) => {
    if (!DESIRED_KEYS.includes(key)) {
      return previousValue;
    }
    if (val !== "yes") {
      return previousValue;
    }
    return previousValue + 1;
  }, 0);
};
