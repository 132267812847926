//import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
//import { Link, useNavigate } from "react-router";

// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import { ApplicationDrawer } from "@/components/Drawers/ApplicationDrawer";
//import ContactFormDrawer from "@/components/Drawers/ContactFormDrawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import ScholarshipDrawer from "@/components/Drawers/ScholarshipDrawer";
import { useAlert } from "@/hooks/use-alert";
//import clientRoutes from "@/lib/client-routes";
import { formatDate } from "@/lib/helpers";
import Contact from "@/models/contact";

import DeleteDialog from "./components/DeleteDialog";
import ForgetDialog from "./components/ForgetDialog";
import Forgotten from "./components/Forgotten";

//import type { TContact } from "@/models/contact/types";

export default function ContactShow({ id }: { id: string }) {
  //const queryClient = useQueryClient();
  //const navigateTo = useNavigate();
  //const contactFormDrawer = useDrawer(ContactFormDrawer);
  const showAlert = useAlert();
  const [showForgetDialog, setShowForgetDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const contact = useQuery(Contact.findById.queryOptions(id));

  const applicationDrawer = useDrawer(ApplicationDrawer);
  const profileDrawer = useDrawer(ProfileDrawer);
  const scholarshipDrawer = useDrawer(ScholarshipDrawer);

  if (contact.isLoading) {
    return (
      <LinearProgress
        color="secondary"
        sx={{ position: "absolute", top: 0, right: 0, left: 0 }}
      />
    );
  }

  if (contact.isError) {
    return <Box sx={{ mb: 2 }}>Error: {contact.error.message}</Box>;
  }

  if (contact.data?.forgotten) {
    return <Forgotten />;
  }

  if (contact.data) {
    return (
      <>
        <div>
          {contact.data.profiles.map((p, i) => {
            return (
              <div
                style={{
                  position: "relative",
                  paddingTop: "1rem",
                  marginBottom: "2rem",
                }}
                key={`${p.uuid}-${i}`}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  sx={{ position: "relative", overflow: "hidden" }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <Typography variant="overline">{p.partner.name}</Typography>
                    <Typography variant="h5">
                      <strong
                        style={{
                          display: "flex",
                        }}
                      >
                        {p.firstName} {p.lastName}
                        <IconButton
                          onClick={() => {
                            profileDrawer.show({
                              email: p.email,
                              partnerID: p.partner._id,
                            });
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </strong>
                    </Typography>
                    <Typography
                      marginBottom={2}
                      variant="h6"
                      sx={{ opacity: 0.7 }}
                    >
                      {p.email}
                      {p.phone ? (
                        <>
                          <br />
                          {p.phone}
                        </>
                      ) : null}
                    </Typography>
                  </div>

                  {/* <ButtonGroup variant="outlined">
                    <Button
                      color="error"
                      onClick={() => {
                        setShowDeleteDialog(true);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>

                    <Button
                      color="error"
                      onClick={() => {
                        setShowForgetDialog(true);
                      }}
                      startIcon={<EmojiPeopleIcon />}
                    >
                      Forget
                    </Button>
                  </ButtonGroup> */}
                  {/* <Button
                    color="secondary"
                    onClick={() => {
                      contactFormDrawer.show({
                        cancel() {
                          contactFormDrawer.remove();
                        },
                        data: contact.data,
                        successCallback(data: Partial<TContact>) {
                          showAlert({ message: "Contact updated!" });
                          queryClient.invalidateQueries({
                            queryKey: ["contact", id],
                          });

                          contactFormDrawer.remove();
                          navigateTo(clientRoutes.contacts.show(data._id));
                        },
                      });
                    }}
                    startIcon={<EditIcon />}
                    variant="outlined"
                  >
                    Edit
                  </Button> */}
                </Stack>

                {contact.data.profiles.length > 1 ? null : (
                  <>
                    <br />
                    <br />
                  </>
                )}

                <Data>
                  {contact.data.address &&
                  Object.keys(contact.data.address).length ? (
                    <Data.Item>
                      <Data.Label sx={{ alignSelf: "flex-start" }}>
                        Address
                      </Data.Label>
                      <Data.Content>
                        {contact.data.address?.street1 ? (
                          <div>{contact.data.address.street1}</div>
                        ) : null}
                        {contact.data.address?.street2 ? (
                          <div>{contact.data.address.street2}</div>
                        ) : null}
                        {contact.data.address?.city ||
                        contact.data.address?.state ||
                        contact.data.address?.region ? (
                          <div>
                            {contact.data.address?.city
                              ? `${contact.data.address?.city}, `
                              : ""}
                            {contact.data.address?.state}{" "}
                            {contact.data.address?.region}
                          </div>
                        ) : null}
                        {contact.data.address?.zipCode ? (
                          <div>{contact.data.address.zipCode}</div>
                        ) : null}
                        {contact.data.address?.country ? (
                          <div>{contact.data.address.country}</div>
                        ) : null}
                      </Data.Content>
                    </Data.Item>
                  ) : null}
                  <Data.Item>
                    <Data.Label>Age</Data.Label>
                    <Data.Content>{contact.data.age}</Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>tcpaConsent</Data.Label>
                    <Data.Content>
                      {contact.data.tcpaConsent ? "yes" : "no"}
                    </Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>underageConsent</Data.Label>
                    <Data.Content>
                      {contact.data.underageConsent ? "yes" : "no"}
                    </Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>crossPartnerMarketingOptIn</Data.Label>
                    <Data.Content>
                      {contact.data.crossPartnerMarketingOptIn ? "yes" : "no"}
                    </Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>touppAccept</Data.Label>
                    <Data.Content>
                      {contact.data.touppAccept ? "yes" : "no"}
                    </Data.Content>
                  </Data.Item>
                </Data>
                {showDeleteDialog ? (
                  <DeleteDialog
                    close={() => {
                      setShowDeleteDialog(false);
                    }}
                    id={id}
                  />
                ) : null}
                {showForgetDialog ? (
                  <ForgetDialog
                    close={() => {
                      setShowForgetDialog(false);
                    }}
                    id={id}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <Typography marginBottom={2} variant="h5" component="h2">
          Applications
        </Typography>
        {contact.data.applications?.length ? (
          contact.data.applications.map((a, i) => {
            return (
              <div key={`${a._id}-${i}`}>
                {a.partner.name} — {a.status}
                {a.completedAt ? ` - ${formatDate(a.completedAt)}` : null}
                <IconButton
                  onClick={() => {
                    applicationDrawer.show({
                      email: a.student.email,
                      partnerId: a.partner._id,
                      successCallback() {
                        showAlert({
                          message: `Application updated`,
                        });
                        applicationDrawer.remove();
                      },
                    });
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            );
          })
        ) : (
          <div>No applications</div>
        )}

        <br />

        <Typography marginBottom={2} variant="h5" component="h2">
          Scholarships
        </Typography>
        {contact.data.scholarships?.length ? (
          contact.data.scholarships.map((s) => {
            return (
              <div key={s._id}>
                {s.partner.name} — {s.status} — {formatDate(s.createdAt)}
                <IconButton
                  onClick={() => {
                    scholarshipDrawer.show({
                      email: s.author,
                      partnerId: s.partner._id,
                    });
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            );
          })
        ) : (
          <div>No scholarships</div>
        )}

        <br />

        <Typography marginBottom={2} variant="h5" component="h2">
          Orders
        </Typography>
        {contact.data.orders?.length ? (
          contact.data.orders.map((a) => {
            return (
              <div key={a._id}>
                {a.partner.name} — {formatDate(a.createdAt)}
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
              </div>
            );
          })
        ) : (
          <div>No orders</div>
        )}

        <br />

        <Typography marginBottom={2} variant="h5" component="h2">
          Enrollments
        </Typography>
        {contact.data.enrollments?.length ? (
          contact.data.enrollments.map((a) => {
            return (
              <div key={a._id}>
                {a.partner.name}{" "}
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
              </div>
            );
          })
        ) : (
          <div>No enrollments</div>
        )}

        <br />
        <br />
      </>
    );
  } else {
    return <div>no contact found</div>;
  }
}
