import { useInfiniteQuery } from "@tanstack/react-query";
import { capitalize } from "lodash-es";
import { useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { MainDetail } from "@/components/MainDetail";
import { STATUS_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import Application from "@/models/application";

import Empty from "./components/Empty";
import SideBar from "./components/SideBar/SideBar";
import ShowApplication from "./show";

import type { TApplicationStatus } from "@/models/application/types";

export default function ApplicationsRoute() {
  useDocumentTitle("Back Office — Applications");

  const { status } = useParams();

  const applicationsQuery = useInfiniteQuery({
    queryKey: Application.findAllByStatus.queryKey(
      status as TApplicationStatus,
    ),
    queryFn(context) {
      const cursor = context.pageParam;
      return Application.findAllByStatus.queryFn(
        status as TApplicationStatus,
        cursor,
      );
    },
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: 0,
  });

  return (
    <MainDetail
      MainComponent={SideBar}
      mainProps={{
        applicationsQuery,
        supTitle: capitalize(STATUS_MAP[status]),
      }}
      DetailComponent={ShowApplication}
      detailPath={clientRoutes.applications.showPath}
      EmptyComponent={() => <Empty applicationsQuery={applicationsQuery} />}
    />
  );
}
